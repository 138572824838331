import { max } from "lodash";
import * as Yup from "yup";
import { HISTORICAL_DATA } from "../contants";

const StandardChartSchema = ({ intl }) => {
  const schema = Yup.object().shape({
    name_en: Yup.string()
      .nullable()
      .when("show_header", {
        is: true,
        then: Yup.string()
          .required(
            intl.formatMessage({
              id: "CLIENT.GENERAL.REQUIRED_FIELD",
            })
          )
          .min(
            3,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
            })
          )
          .max(
            50,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
            })
          ),
      }),
    name_vi: Yup.string()
      .nullable()
      .when("show_header", {
        is: true,
        then: Yup.string()
          .required(
            intl.formatMessage({
              id: "CLIENT.GENERAL.REQUIRED_FIELD",
            })
          )
          .min(
            3,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
            })
          )
          .max(
            50,
            intl.formatMessage({
              id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
            })
          ),
      }),
    historical_start_time: Yup.date().when("data_mode", {
      is: (option) => option === HISTORICAL_DATA,
      then: Yup.date().when("historical_time_range", {
        is: (value) => value === "0",
        then: Yup.date()
          .typeError(
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE.INVALID",
            })
          )
          .max(
            Yup.ref("historical_end_time"),
            intl.formatMessage({
              id: "CLIENT.SYNC_DATA_REQUEST.FROM_TO_DATE.INVALID",
            })
          )
          .nullable()
          .required(
            intl.formatMessage({
              id: "CLIENT.GENERAL.REQUIRED_FIELD",
            })
          ),
      }),
    }),
    historical_end_time: Yup.date().when("data_mode", {
      is: (option) => option === HISTORICAL_DATA,
      then: Yup.date().when("historical_time_range", {
        is: (value) => value === "0",
        then: Yup.date()
          .typeError(
            intl.formatMessage({
              id: "CLIENT.GENERAL.DATE.INVALID",
            })
          )
          .nullable()
          .required(
            intl.formatMessage({
              id: "CLIENT.GENERAL.REQUIRED_FIELD",
            })
          ),
      }),
    }),

    // Left Y-Axis validation
    y_left_min: Yup.number().when(["y_axis_left", "y_left_auto"], {
      is: (y_axis_left, y_left_auto) => y_axis_left && !y_left_auto,
      then: Yup.number().test(
        "less-than-max",
        intl.formatMessage({ id: "CLIENT.DASHBOARD.MIN_MUST_BE_SMALLER" }),
        function (value) {
          const { y_left_max } = this.parent;
          return !value || !y_left_max || value < y_left_max;
        }
      ),
    }),

    y_left_max: Yup.number().when(["y_axis_left", "y_left_auto"], {
      is: (y_axis_left, y_left_auto) => y_axis_left && !y_left_auto,
      then: Yup.number().test(
        "greater-than-min",
        intl.formatMessage({ id: "CLIENT.DASHBOARD.MAX_MUST_BE_GREATER" }),
        function (value) {
          const { y_left_min } = this.parent;
          return !value || !y_left_min || value > y_left_min;
        }
      ),
    }),

    // Right Y-Axis validation
    y_right_min: Yup.number().when(["y_axis_right", "y_right_auto"], {
      is: (y_axis_right, y_right_auto) => y_axis_right && !y_right_auto,
      then: Yup.number().test(
        "less-than-max",
        intl.formatMessage({ id: "CLIENT.DASHBOARD.MIN_MUST_BE_SMALLER" }),
        function (value) {
          const { y_right_max } = this.parent;
          return !value || !y_right_max || value < y_right_max;
        }
      ),
    }),

    y_right_max: Yup.number().when(["y_axis_right", "y_right_auto"], {
      is: (y_axis_right, y_right_auto) => y_axis_right && !y_right_auto,
      then: Yup.number().test(
        "greater-than-min",
        intl.formatMessage({ id: "CLIENT.DASHBOARD.MAX_MUST_BE_GREATER" }),
        function (value) {
          const { y_right_min } = this.parent;
          return !value || !y_right_min || value > y_right_min;
        }
      ),
    }),
  });
  return schema;
};

export default StandardChartSchema;
