import { Box, Button, Grid, Tab, Tabs } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Slider from "@material-ui/core/Slider";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import * as Yup from "yup";

import lineStyleData from "../Data/data/line-style";
import lineWidthData from "../Data/data/line-width";
import markerTypeData from "../Data/data/marker-type";
import SharedDropdown from "../Data/SharedDropdown";

import BarChartIcon from "@material-ui/icons/BarChart";
import TimelineIcon from "@material-ui/icons/Timeline";

import { useFormik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import swal from "sweetalert";
import ColorPicker from "../../../../../../shared/components/ColorPicker";
import SelectParamsByStaionDropdown from "../../../../../../shared/components/SelectParamsByStaionDropdown";
import SharedInputField from "../../../../../../shared/components/SharedInputField";
import SharedSelectField from "../../../../../../shared/components/SharedSelectField";
import { useLanguage } from "../../../../../../shared/hooks/useLanguage";
import {
  COLUMN_FILL_STYLES,
  COLUMN_PLACEMENT,
  COLUMN_TYPES,
  LINE_FILL_STYLES,
  LINE_TYPES,
  MARKER_POSITIONS,
  MARKER_SIZES,
  STATE_THRESHOLDS,
} from "../constants";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "50%",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4, 5),
  },
  formControl: {
    width: "100%",
  },
  textField: { background: "white" },
}));

const PrettoSlider = withStyles({
  root: {
    color: "#3699ff",
    height: 10,
    width: "100%",
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
    "& > span > span": {
      color: "#fff",
      fontWeight: 600,
    },
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const initialValues = {
  name_en: "New Chart",
  name_vi: "Biểu Đồ Mới",
  parameter_id: null,
  style: "LINE_SERIES",
  line_type: "NORMAL",
  column_fill_type: "NONE",
  line_fill_type: "NONE",
  column_type: "NORMAL",
  line_width: 2,
  line_style: 0,
  marker_type: 1,
  marker_size: 2,
  state_thresholds: "ALL_STATES",
  color: "AUTOMATIC",
  main_color: "#3699FF",
  marker_position: "LEFT",
  column_placement: "ON_TICKS",
  fill_opacity: 75,
  station_id: null,
  y_axis: "left",
};

const nameInvalid = (data, nameEn, nameVi) => {
  for (let item of data) {
    if (item["name_en"] === nameEn.trim()) {
      return item["name_en"];
    }
    if (item["name_vi"] === nameVi.trim()) {
      return item["name_vi"];
    }
  }
  return "";
};

const StandardChartSection = (props) => {
  const classes = useStyles();
  const [tab, setTab] = useState(0);
  const intl = useIntl();
  const [language] = useLanguage();

  // const handleSliderChange = (name) => (event, newValue) => {
  //   const newValues = {
  //     ...values,
  //     [name]: newValue,
  //     opacity: newValue / 100,
  //   };

  //   setValues(newValues);
  //   props['onChange'](newValues);
  // };

  const handleChangeTab = (event, newTab) => {
    setTab(newTab);
  };

  const schema = Yup.object().shape({
    name_en: Yup.string()
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
        })
      )
      .max(
        50,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })
      ),
    name_vi: Yup.string()
      .required(
        intl.formatMessage({
          id: "CLIENT.GENERAL.REQUIRED_FIELD",
        })
      )
      .min(
        3,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MINIMUM_SYMBOLS",
        })
      )
      .max(
        50,
        intl.formatMessage({
          id: "CLIENT.GENERAL.MAXIMUM_SYMBOLS",
        })
      ),
  });

  const formik = useFormik({
    initialValues: {
      name_en: props.data?.name_en || initialValues.name_en,
      name_vi: props.data?.name_vi || initialValues.name_vi,
      parameter_id: props.data?.parameter_id || initialValues.parameter_id,
      style: props.data?.style || initialValues.style,
      line_type: props.data?.line_type || initialValues.line_type,
      column_type: props.data?.column_type || initialValues.column_type,
      column_fill_type:
        props.data?.style !== "LINE_SERIES"
          ? props.data?.fill_type
          : initialValues.column_fill_type,
      line_fill_type:
        props.data?.style === "LINE_SERIES"
          ? props.data?.fill_type
          : initialValues.line_fill_type,
      line_width: props.data?.line_width || initialValues.line_width,
      line_style: props.data?.line_style || initialValues.line_style,
      marker_type: props.data?.marker_type || initialValues.marker_type,
      marker_size: props.data?.marker_size || initialValues.marker_size,
      state_thresholds:
        props.data?.state_thresholds || initialValues.state_thresholds,
      color: props.data?.color || initialValues.color,
      main_color: props.data?.main_color || initialValues.main_color,
      marker_position:
        props.data?.marker_position || initialValues.marker_position,
      column_placement:
        props.data?.column_placement || initialValues.column_placement,
      fill_opacity: props.data?.fill_opacity || initialValues.fill_opacity,
      station_id: props.data?.station_id || initialValues.station_id,
      y_axis: props.data?.y_axis || initialValues.y_axis,
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: async (values) => {
      if (!values["parameter_id"]) {
        swal({
          title: intl.formatMessage({
            id: "CLIENT.DASHBOARD.ADD_CHART_FAILED_TITLE",
          }),
          text: intl.formatMessage({
            id: "CLIENT.DASHBOARD.MISSING_PARAMETERS",
          }),
          icon: "error",
          button: intl.formatMessage({
            id: "CLIENT.GENERAL.OK_BUTTON",
          }),
        });
        return;
      }
      const usedName = nameInvalid(
        props["charts"].filter((item) => item["id"] !== props["data"].id),
        values["name_en"],
        values["name_vi"]
      );
      if (usedName) {
        swal({
          title: intl.formatMessage({
            id: "CLIENT.DASHBOARD.ADD_CHART_FAILED_TITLE",
          }),
          text: `${
            language === "en" ? "Name " : "Tên "
          } "${usedName}" ${intl.formatMessage({
            id: "CLIENT.DASHBOARD.IS_USED",
          })}`,
          icon: "error",
          button: intl.formatMessage({
            id: "CLIENT.GENERAL.OK_BUTTON",
          }),
        });
        return;
      }
      const changedValues = {
        ...values,
        fill_type:
          values["style"] === "LINE_SERIES"
            ? values["line_fill_type"]
            : values["column_fill_type"],
        marker_position:
          values["style"] === "LINE_SERIES" && values["line_type"] === "STEP"
            ? values["marker_position"]
            : "",
        column_placement:
          values["style"] === "COLUMN_SERIES" ? values["column_placement"] : "",
        fill_opacity:
          values["style"] === "COLUMN_SERIES" ? values["fill_opacity"] : "",
      };
      delete changedValues["column_fill_type"];
      delete changedValues["line_fill_type"];

      props["onChange"](changedValues);
    },
  });

  React.useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, []);

  React.useEffect(() => {
    if (formik) {
      props["setForms"]((prevForms) => {
        return [
          ...prevForms,
          {
            id: props["id"],
            form: formik,
          },
        ];
      });
    }
  }, [props["id"]]);

  const handleSliderChange = (event, newValue) => {
    formik.setFieldValue("fill_opacity", newValue);
  };

  const renderGeneralTab = () => {
    return (
      <>
        <Box mb={3}>
          <SharedInputField
            name="name_en"
            label={intl.formatMessage({ id: "CLIENT.DASHBOARD.NAME_EN_LABEL" })}
            type="text"
            required
            className="bg-white"
            placeholder={intl.formatMessage({
              id: "CLIENT.DASHBOARD.NAME_EN_HINT",
            })}
            {...formik.getFieldProps("name_en")}
            disabled={formik.isSubmitting}
            errorMsg={formik.touched["name_en"] && formik.errors["name_en"]}
          />
        </Box>
        <Box mb={3}>
          <SharedInputField
            name="name_vi"
            label={intl.formatMessage({ id: "CLIENT.DASHBOARD.NAME_VI_LABEL" })}
            type="text"
            required
            className="bg-white"
            placeholder={intl.formatMessage({
              id: "CLIENT.DASHBOARD.NAME_VI_HINT",
            })}
            {...formik.getFieldProps("name_vi")}
            disabled={formik.isSubmitting}
            errorMsg={formik.touched["name_vi"] && formik.errors["name_vi"]}
          />
        </Box>

        <Box mb={3}>
          <Box className="form-label">
            <p className="required">*</p>
            <FormattedMessage id="CLIENT.PARAMETERS_ACTIONS.PARAMETERS" />
          </Box>
          <SelectParamsByStaionDropdown
            data={props.parameters || []}
            value={{
              parameterId: formik.values["parameter_id"],
              stationId: formik.values["station_id"],
            }}
            onChange={({ stationId, parameterId }) => {
              formik.setFieldValue("parameter_id", parameterId);
              formik.setFieldValue("station_id", stationId);
            }}
          />
        </Box>
        <Box mb={3}>
          <Box className="form-label" style={{ marginBottom: "5px" }}>
            <FormattedMessage id="CLIENT.DASHBOARD.Y_AXIS" />
          </Box>
          <RadioGroup
            aria-label="y_axis"
            name="y_axis"
            value={formik.values.y_axis || "left"}
            onChange={(e) => formik.setFieldValue("y_axis", e.target.value)}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <FormControlLabel
              value="left"
              control={<Radio color="primary" />}
              label={intl.formatMessage({
                id: "CLIENT.DASHBOARD.LEFT_AXIS",
              })}
            />
            <FormControlLabel
              value="right"
              control={<Radio color="primary" />}
              label={intl.formatMessage({
                id: "CLIENT.DASHBOARD.RIGHT_AXIS",
              })}
            />
          </RadioGroup>
        </Box>
      </>
    );
  };

  const renderStyleTab = () => {
    return (
      <div>
        <div className="standard-chart-section-style">
          <div className="standard-chart-section-style-menu">
            <Box
              display="flex"
              alignItems="center"
              className={
                formik["values"]["style"] === "LINE_SERIES"
                  ? "standard-chart-section-style-menu-item active"
                  : "standard-chart-section-style-menu-item"
              }
              onClick={() => formik.setFieldValue("style", "LINE_SERIES")}
            >
              <TimelineIcon />
              <Box ml={1}>
                <FormattedMessage id="CLIENT.DASHBOARD.LINE_SERIES" />
              </Box>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              className={
                formik["values"]["style"] === "COLUMN_SERIES"
                  ? "standard-chart-section-style-menu-item active"
                  : "standard-chart-section-style-menu-item"
              }
              onClick={() => formik.setFieldValue("style", "COLUMN_SERIES")}
            >
              <BarChartIcon />
              <Box ml={1}>
                <FormattedMessage id="CLIENT.DASHBOARD.COLUMN_SERIES" />
              </Box>
            </Box>
          </div>

          <div className="standard-chart-section-style-content">
            <h3>
              {formik["values"]["style"] === "LINE_SERIES" ? (
                <FormattedMessage id="CLIENT.DASHBOARD.LINE_TYPE" />
              ) : (
                <FormattedMessage id="CLIENT.DASHBOARD.COLUMN_TYPE" />
              )}
            </h3>
            <div className="standard-chart-section-style-cards">
              {(formik["values"]["style"] === "LINE_SERIES"
                ? LINE_TYPES
                : COLUMN_TYPES
              ).map((item) => (
                <div
                  key={item.value}
                  className={
                    formik["values"][
                      formik["values"]["style"] === "LINE_SERIES"
                        ? "line_type"
                        : "column_type"
                    ] === item.value
                      ? "standard-chart-section-card active"
                      : "standard-chart-section-card"
                  }
                  onClick={() =>
                    formik.setFieldValue(
                      formik["values"]["style"] === "LINE_SERIES"
                        ? "line_type"
                        : "column_type",
                      item.value
                    )
                  }
                >
                  <span>{item[`name_${language}`]}</span>
                  <img
                    src={`/images/standard-chart/${item.image}.png`}
                    alt=""
                  />
                </div>
              ))}
            </div>

            <h3>
              <FormattedMessage id="CLIENT.DASHBOARD.FILL_STYLE" />
            </h3>
            <div className="standard-chart-section-style-cards">
              {(formik["values"]["style"] === "LINE_SERIES"
                ? LINE_FILL_STYLES
                : COLUMN_FILL_STYLES
              ).map((item) => (
                <div
                  key={item.value}
                  className={
                    formik["values"][
                      formik["values"]["style"] === "LINE_SERIES"
                        ? "line_fill_type"
                        : "column_fill_type"
                    ] === item.value
                      ? "standard-chart-section-card active"
                      : "standard-chart-section-card"
                  }
                  onClick={() =>
                    formik.setFieldValue(
                      formik["values"]["style"] === "LINE_SERIES"
                        ? "line_fill_type"
                        : "column_fill_type",
                      item.value
                    )
                  }
                >
                  <span>{item[`name_${language}`]}</span>
                  <img
                    src={`/images/standard-chart/${item.image}.png`}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCustomizedTab = () => {
    return (
      <div>
        <Grid container spacing={2}>
          {formik["values"]["style"] === "COLUMN_SERIES" && (
            <>
              <Grid item xs={6}>
                <Box mb={2} mt={2}>
                  <SharedSelectField
                    label={intl.formatMessage({
                      id: "CLIENT.DASHBOARD.COLUMN_PLACEMENT",
                    })}
                    disableClearable
                    name="column_placement"
                    required
                    options={COLUMN_PLACEMENT.map((item) => ({
                      id: item.value,
                      value: item.value,
                      label: item[`name_${language}`],
                    }))}
                    defaultValue={formik.values["column_placement"]}
                    onChange={formik.setFieldValue}
                    disabled={formik.isSubmitting}
                    className="bg-white"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box mb={2} mt={2}>
                  <Box className="form-label" style={{ marginBottom: "5px" }}>
                    <p className="required">*</p>
                    <FormattedMessage id="CLIENT.DASHBOARD.FILL_OPACITY" />
                  </Box>
                  <Box style={{ paddingTop: "20px" }}>
                    <PrettoSlider
                      onChange={handleSliderChange}
                      valueLabelDisplay="auto"
                      aria-label="pretto slider"
                      value={formik["values"]["fill_opacity"]}
                      step={1}
                    />
                  </Box>
                </Box>
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <Box mb={2}>
              <SharedDropdown
                label={intl.formatMessage({
                  id: "CLIENT.DASHBOARD.LINE_WIDTH",
                })}
                required
                name="line_width"
                value={formik["values"]["line_width"]}
                data={lineWidthData}
                onChange={(value) => formik.setFieldValue("line_width", value)}
                displayNameType="tooltip"
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box mb={2}>
              <SharedDropdown
                label={intl.formatMessage({
                  id: "CLIENT.DASHBOARD.LINE_STYLE",
                })}
                required
                name="line_style"
                value={formik["values"]["line_style"]}
                data={lineStyleData}
                onChange={(value) => formik.setFieldValue("line_style", value)}
                displayNameType="tooltip"
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box mb={2}>
              <SharedDropdown
                label={intl.formatMessage({
                  id: "CLIENT.DASHBOARD.MARKER_TYPE",
                })}
                required
                name="marker_type"
                value={formik["values"]["marker_type"]}
                data={markerTypeData}
                onChange={(value) => formik.setFieldValue("marker_type", value)}
                displayNameType="tooltip"
              />
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Box mb={2}>
              <SharedSelectField
                label={intl.formatMessage({
                  id: "CLIENT.DASHBOARD.MARKER_SIZE",
                })}
                disableClearable
                name="marker_size"
                required
                options={MARKER_SIZES.map((item) => ({
                  id: item.value,
                  value: item.value,
                  label: item.name,
                }))}
                defaultValue={formik.values["marker_size"]}
                onChange={formik.setFieldValue}
                disabled={formik.isSubmitting}
                className="bg-white"
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box mb={2}>
              <SharedSelectField
                label={intl.formatMessage({
                  id: "CLIENT.DASHBOARD.STATE_THRESHOLDS",
                })}
                disableClearable
                name="state_thresholds"
                required
                options={STATE_THRESHOLDS.map((item) => ({
                  id: item.value,
                  value: item.value,
                  label: item[`name_${language}`],
                }))}
                defaultValue={formik.values["state_thresholds"]}
                onChange={formik.setFieldValue}
                disabled={formik.isSubmitting}
                className="bg-white"
              />
            </Box>
          </Grid>

          {formik["values"]["style"] === "LINE_SERIES" &&
            formik["values"]["line_type"] === "STEP" && (
              <Grid item xs={12}>
                <Box mb={2}>
                  <SharedSelectField
                    label={intl.formatMessage({
                      id: "CLIENT.DASHBOARD.MARKER_POSITION",
                    })}
                    disableClearable
                    name="marker_position"
                    required
                    options={MARKER_POSITIONS.map((item) => ({
                      id: item.value,
                      value: item.value,
                      label: item[`name_${language}`],
                    }))}
                    defaultValue={formik.values["marker_position"]}
                    onChange={formik.setFieldValue}
                    disabled={formik.isSubmitting}
                    className="bg-white"
                  />
                </Box>
              </Grid>
            )}

          <Grid item xs={12}>
            <Box>
              <Box className="form-label" style={{ marginBottom: "5px" }}>
                <p className="required">*</p>
                <FormattedMessage id="CLIENT.DASHBOARD.COLOR" />
              </Box>

              <RadioGroup
                aria-label="color"
                name="color"
                value={formik["values"]["color"]}
                onChange={(e) => formik.setFieldValue("color", e.target.value)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <FormControlLabel
                  value="AUTOMATIC"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: "CLIENT.DASHBOARD.AUTOMATIC",
                  })}
                />
                <FormControlLabel
                  value="FIXED"
                  control={<Radio color="primary" />}
                  label={intl.formatMessage({
                    id: "CLIENT.DASHBOARD.FIXED",
                  })}
                />
              </RadioGroup>
            </Box>
          </Grid>
          {formik["values"]["color"] === "FIXED" && (
            <Grid item xs={6}>
              <Box>
                <Box className="form-label" style={{ marginBottom: "5px" }}>
                  <p className="required">*</p>
                  <FormattedMessage id="CLIENT.DASHBOARD.MAIN_COLOR" />
                </Box>

                <ColorPicker
                  disabled={formik.isSubmitting}
                  color={formik["values"]["main_color"]}
                  position="right"
                  onChange={(colorValue) =>
                    formik.setFieldValue("main_color", colorValue)
                  }
                />
              </Box>
            </Grid>
          )}
        </Grid>

        <Box mt={2}>
          <Button
            type="button"
            className="custom-button"
            onClick={() => formik.setValues(initialValues)}
          >
            <FormattedMessage id="CLIENT.DASHBOARD.RESET_TO_DAFAULT" />
          </Button>
        </Box>
      </div>
    );
  };

  return (
    <div className="standard-chart-section">
      <Tabs
        value={tab}
        onChange={handleChangeTab}
        indicatorColor="primary"
        aria-label="simple tabs example"
        style={{
          borderBottom: "3px solid rgb(210 210 210 / 29%)",
          marginBottom: 16,
        }}
      >
        <Tab
          label={intl.formatMessage({
            id: "CLIENT.STATION_ACTIONS.PARAMETERS_TAB",
          })}
        />
        <Tab
          label={intl.formatMessage({
            id: "CLIENT.DASHBOARD.FEATURES.STYLE",
          })}
        />
        <Tab
          label={intl.formatMessage({
            id: "CLIENT.DASHBOARD.FEATURES.CUSTOMIZED",
          })}
        />
      </Tabs>

      <div className="standard-chart-section-content">
        <div>
          {tab === 0 && renderGeneralTab()}
          {tab === 1 && renderStyleTab()}
          {tab === 2 && renderCustomizedTab()}
        </div>
      </div>
    </div>
  );
};

export default StandardChartSection;
