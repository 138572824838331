import { getRandomDarkColor } from "app/utils/color";
import { keyBy, map } from "lodash";
import { useCallback, useState } from "react";
import {
  getChartOption,
  getColumnStyle,
  getFormattedValue,
  getLineStyle,
} from "app/shared/components/Dashboard/Echart/echart.utils";
import useDetectDevice from "app/shared/hooks/useDetectDevice";

const DEFAULT_COLOR_SETTING = "AUTOMATIC";

const useStandardChart = ({ language, header_color }) => {
  const [chartConfig, setChartConfig] = useState();
  const [listParameterId, setParameterId] = useState({});
  const { deviceType } = useDetectDevice();

  const getYAxisConfig = (standardChartConfig) => {
    const {
      y_axis_left,
      y_axis_right,
      y_left_label,
      y_right_label,
      y_left_unit,
      y_right_unit,
      y_left_min,
      y_left_max,
      y_right_min,
      y_right_max,
      y_left_auto,
      y_right_auto,
    } = standardChartConfig;

    const yAxes = [];

    // Always add left axis if enabled or if it's the only one
    if (y_axis_left || !y_axis_right) {
      yAxes.push({
        position: "left",
        label: y_left_label || "",
        unit: y_left_unit || "",
        min: y_left_auto ? null : y_left_min,
        max: y_left_auto ? null : y_left_max,
      });
    }

    // Add right axis if enabled
    if (y_axis_right) {
      yAxes.push({
        position: "right",
        label: y_right_label || "",
        unit: y_right_unit || "",
        min: y_right_auto ? null : y_right_min,
        max: y_right_auto ? null : y_right_max,
      });
    }

    return yAxes;
  };

  const getParameterData = (receiveData, chartConfigByParameter) => {
    let parameterData = {};
    let parameterIds = {};
    let isNoData = true;

    receiveData.forEach((data) => {
      parameterIds[data.parameterDto.parameterId] =
        data.parameterDto.parameterName;

      parameterData[data.parameterDto.parameterId] = map(
        data.dataPointDtos,
        (point) => {
          isNoData = false;
          const format =
            chartConfigByParameter[data.parameterDto.parameterId]?.parameter
              ?.parameter_format_text;

          const x = point.eventTime;
          const y = getFormattedValue(point.value, format);

          return [x, y];
        }
      );
    });

    setParameterId(parameterIds);

    return {
      parameterData,
      isNoData,
      listParameterId,
    };
  };

  /* parameterDatas is an array of objects
  it's structure is as follows:
  [
    {
      dataPointDtos: [
          {
            eventTime: String,
            isAlarm: Boolean,
            value: Number
          },
          ...
      ],
      parameterDto: {
        parameterId: Number,
        stationId: Number,
        organizationId: Number,
      }
    },
    ...
  ]
  */
  const receiveDataAndConfig = useCallback(
    (receiveData, standardChartConfig) => {
      if (!receiveData || !receiveData.parameterDatas || !standardChartConfig)
        return;

      const chartConfigByParameter = keyBy(
        standardChartConfig.dashboard_standard_chart_parameter,
        "parameter_id"
      );

      const { parameterData, isNoData } = getParameterData(
        receiveData.parameterDatas,
        chartConfigByParameter
      );

      if (isNoData) return;

      const DEFAULT_WIDTH = 2;
      let series = [];
      let listColor = [];

      standardChartConfig.dashboard_standard_chart_parameter.forEach(
        (element) => {
          const isColumnType =
            element.style === "COLUMN_SERIES" ? "bar" : "line";
          const width = +element.line_width;
          const fillType = element.fill_type;
          const fillOpacity = +element.fill_opacity;
          const markerType = +element.marker_type;
          const markerSize = +element.marker_size;
          const lineType = element.line_type;
          const lineStyle = element.line_style;
          const color =
            element.color === DEFAULT_COLOR_SETTING
              ? getRandomDarkColor()
              : element.main_color;
          listColor.push(color);

          let chartStyle;
          if (element.style === "COLUMN_SERIES") {
            chartStyle = getColumnStyle(width, color, fillType, fillOpacity);
          } else {
            chartStyle = getLineStyle(
              markerType,
              markerSize,
              color,
              fillType,
              lineType,
              width,
              lineStyle
            );
          }

          // Determine correct axis index based on y_axis value and available axes
          const hasLeftAxis = standardChartConfig.y_axis_left;
          const hasRightAxis = standardChartConfig.y_axis_right;

          let yAxisPosition = element.y_axis || "left"; // Default to left if not specified

          // Handle cases where the selected axis is disabled
          if (
            (yAxisPosition === "left" && !hasLeftAxis) ||
            (yAxisPosition === "right" && !hasRightAxis)
          ) {
            yAxisPosition = hasLeftAxis ? "left" : "right";
          }

          // Calculate yAxisIndex based on position and available axes
          const yAxisIndex = yAxisPosition === "right" && hasRightAxis ? 1 : 0;

          let seriesData = {
            id: `${element.parameter_id}_${crypto.randomUUID()}`,
            type: isColumnType,
            name: element[`name_${language}`],
            data: parameterData[element.parameter_id],
            yAxisIndex: yAxisIndex,
          };
          seriesData = Object.assign(chartStyle, seriesData);

          series.push(seriesData);
        }
      );

      const { gridlines: showGrid, legend: showLegend } =
        standardChartConfig || {};

      const yAxisConfig = getYAxisConfig(standardChartConfig);

      const chartOption = getChartOption({
        dataSeries: series,
        showGrid,
        showLegend,
        header_color,
        deviceType,
        listColor,
        yAxisConfig,
        padding: {
          top: 60,
          left: 60,
          right: standardChartConfig.y_axis_right ? 85 : 60,
          containLabel: true,
        },
      });

      setChartConfig(chartOption);
    },

    [language, deviceType]
  );
  return { chartConfig, listParameterId, receiveDataAndConfig };
};

export default useStandardChart;
