export const FEATURES_OPTIONS = [
  {
    name_en: "Zoom",
    name_vi: "Phóng To",
    value: "zoom",
  },
  {
    name_en: "Gridlines",
    name_vi: "Đường Lưới",
    value: "gridlines",
  },
  {
    name_en: "Legend",
    name_vi: "Chú Thích",
    value: "legend",
  },
  {
    name_en: "Range Selection",
    name_vi: "Chọn Khoảng",
    value: "range_selection",
  },
  {
    name_en: "Full Screen",
    name_vi: "Toàn Màn Hình",
    value: "full_screen",
  },
];

export const CHART_TYPES = [
  {
    value: "COLUMN_SERIES",
    name_en: "Column Series",
    name_vi: "Chuỗi Cột",
  },
  {
    value: "LINE_SERIES",
    name_en: "Line Series",
    name_vi: "Chuỗi Đường",
  },
];

export const LINE_TYPES = [
  {
    value: "NORMAL",
    name_en: "Normal",
    name_vi: "Tiêu Chuẩn",
    image: "line",
  },
  {
    value: "STEP",
    name_en: "Step",
    name_vi: "Bậc Thang",
    image: "step",
  },
  {
    value: "SPLINE",
    name_en: "Spline",
    name_vi: "Đường Xoắn",
    image: "spline",
  },
];

export const COLUMN_TYPES = [
  {
    value: "NORMAL",
    name_en: "Normal",
    name_vi: "Tiêu Chuẩn",
    image: "column-solid",
  },
  {
    value: "GROUPED",
    name_en: "Grouped",
    name_vi: "Nhóm",
    image: "column-group",
  },
  {
    value: "STACKED",
    name_en: "Stacked",
    name_vi: "Xếp Chồng",
    image: "column-stack",
  },
];

export const LINE_FILL_STYLES = [
  {
    value: "NONE",
    name_en: "None",
    name_vi: "Không",
    image: "line",
  },
  {
    value: "SOLID",
    name_en: "Solid",
    name_vi: "Cường Độ",
    image: "solid",
  },
  {
    value: "GRADIENT",
    name_en: "Gradient",
    name_vi: "Khuynh Độ",
    image: "gradient",
  },
];

export const COLUMN_FILL_STYLES = [
  {
    value: "NONE",
    name_en: "None",
    name_vi: "Không",
    image: "column-none",
  },
  {
    value: "SOLID",
    name_en: "Solid",
    name_vi: "Cường Độ",
    image: "column-solid",
  },
  {
    value: "GRADIENT",
    name_en: "Gradient",
    name_vi: "Khuynh Độ",
    image: "column-gradient",
  },
];

export const MARKER_SIZES = [
  {
    value: 2,
    name: "2",
  },
  {
    value: 3,
    name: "3",
  },
  {
    value: 4,
    name: "4",
  },
  {
    value: 5,
    name: "5",
  },
  {
    value: 6,
    name: "6",
  },
];

// export const QUALITY_STYLES = [
//   {
//     value: "NONE",
//     name_en: "None",
//     name_vi: "None"
//   },
//   {
//     value: "MARKER_HOVER",
//     name_en: "Marker (Hover)",
//     name_vi: "Marker (Hover)"
//   },
//   {
//     value: "MARKER",
//     name_en: "Marker",
//     name_vi: "Marker"
//   },
//   {
//     value: "MARKER_LINE",
//     name_en: "Marker, Line",
//     name_vi: "Marker, Line"
//   },
//   {
//     value: "MARKER_LINE_FILL",
//     name_en: "Marker, Line, Fill",
//     name_vi: "Marker, Line, Fill"
//   },
// ]

export const STATE_THRESHOLDS = [
  {
    value: "HIDDEN",
    name_en: "Hidden",
    name_vi: "Ẩn",
  },
  {
    value: "ALL_STATES",
    name_en: "All States",
    name_vi: "Tất Cả Trạng Thái",
  },
  {
    value: "ALARMS_STATES_ONLY",
    name_en: "Alarms States Only",
    name_vi: "Chỉ Trang Thái Báo Động",
  },
  {
    value: "NON_ALARMS_STATES_ONLY",
    name_en: "Non-Alarms States Only",
    name_vi: "Chỉ Trạng Thái Không Báo Động",
  },
  {
    value: "USER_NOTIFICATION_STATES_ONLY",
    name_en: "User Notification States Only",
    name_vi: "Chỉ Trạng Thái Thông Báo Cho Người Dùng",
  },
];

export const MARKER_POSITIONS = [
  {
    value: "LEFT",
    name_en: "Left",
    name_vi: "Trái",
  },
  {
    value: "CENTER",
    name_en: "Center",
    name_vi: "Giữa",
  },
  {
    value: "RIGHT",
    name_en: "Right",
    name_vi: "Phải",
  },
];
export const COLUMN_PLACEMENT = [
  {
    value: "BETWEEN_TICKS",
    name_en: "Between Ticks",
    name_vi: "Giữa Điểm Đánh Dấu",
  },
  {
    value: "ON_TICKS",
    name_en: "On Ticks",
    name_vi: "Ngay Điểm Đánh Dấu",
  },
];

export const Y_AXIS_LABELS = {
  y_axis_left: {
    name_en: "Y Left Axis",
    name_vi: "Trục Y Trái"
  },
  y_axis_right: {
    name_en: "Y Right Axis",
    name_vi: "Trục Y Phải"
  }
};
