import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import ConfigureArea from "app/shared/components/Dashboard/ConfigureArea";
import DataModeConfigurator from "app/shared/components/Dashboard/DataModeConfigurator";
import HeaderConfigurator from "app/shared/components/Dashboard/HeaderConfigurator";
import { FormattedMessage } from "react-intl";
import { COMBINED_DATA, HISTORICAL_DATA, PREDICTION_DATA } from "../contants";
import ChartList from "./ChartList";
import { FEATURES_OPTIONS, Y_AXIS_LABELS } from "./constants";
import SharedInputField from "app/shared/components/SharedInputField";
import InfoIcon from "@material-ui/icons/Info";

const StandardChartForm = ({ formik, intl, language, parameters }) => {
  const handleAxisToggle = (axisName, value) => {
    formik.setFieldValue(axisName, value);
    if (
      !value &&
      !formik.values[
        axisName === "y_axis_left" ? "y_axis_right" : "y_axis_left"
      ]
    ) {
      formik.setFieldValue("y_axis_left", true);
      return;
    }

    if (!value) {
      const newAxisPosition = axisName === "y_axis_left" ? "right" : "left";
      const updatedParameters =
        formik.values.dashboard_standard_chart_parameter.map((param) => ({
          ...param,
          y_axis:
            param.y_axis === (axisName === "y_axis_left" ? "left" : "right")
              ? newAxisPosition
              : param.y_axis,
        }));

      formik.setFieldValue(
        "dashboard_standard_chart_parameter",
        updatedParameters
      );
    }
  };

  const validateMinMaxValues = (side, value, fieldName) => {
    const isMax = fieldName.includes("max");
    const compareValue = isMax
      ? formik.values[`y_${side}_min`]
      : formik.values[`y_${side}_max`];

    if (isMax && Number(value) <= Number(compareValue)) {
      formik.setFieldError(
        `y_${side}_max`,
        intl.formatMessage({
          id: "CLIENT.DASHBOARD.MAX_MUST_BE_GREATER",
        })
      );
    } else if (!isMax && Number(value) >= Number(compareValue)) {
      formik.setFieldError(
        `y_${side}_min`,
        intl.formatMessage({
          id: "CLIENT.DASHBOARD.MIN_MUST_BE_SMALLER",
        })
      );
    } else {
      formik.setFieldError(`y_${side}_max`, undefined);
      formik.setFieldError(`y_${side}_min`, undefined);
    }
  };

  const handleMinMaxChange = (e) => {
    const { name, value } = e.target;
    const side = name.includes("left") ? "left" : "right";
    formik.setFieldValue(name, value);
    validateMinMaxValues(side, value, name);
  };

  return (
    <div className="value-modal-body">
      <form onSubmit={formik.handleSubmit}>
        <ConfigureArea
          mb={30}
          title={intl.formatMessage({
            id: "CLIENT.DASHBOARD.HEADER_LABEL",
          })}
        >
          <HeaderConfigurator formik={formik} />
        </ConfigureArea>

        <ConfigureArea
          mb={30}
          title={intl.formatMessage({
            id: "CLIENT.DASHBOARD.DATAMODE",
          })}
        >
          <DataModeConfigurator
            showHistoricTimeRangep
            options={[HISTORICAL_DATA, PREDICTION_DATA, COMBINED_DATA]}
            values={{
              historical_time_range: `${formik.values["historical_time_range"]}-${formik.values["historical_time_range_type"]}`,
              prediction_time_range: `${formik.values["prediction_time_range"]}-${formik.values["prediction_time_range_type"]}`,
              historical_start_time: formik.values.historical_start_time,
              historical_end_time: formik.values.historical_end_time,
            }}
            mode={formik.values["data_mode"]}
            onChange={(name, value) => {
              formik.setFieldValue(name, value);
            }}
            predictionLabel={
              <FormattedMessage id="CLIENT.DASHBOARD.DATAMODE.HISTORICAL.TIMEPOINT" />
            }
            combinedDataPredictionLabel={
              <FormattedMessage id="CLIENT.DASHBOARD.DATAMODE.COMBINED_PREDICTION_LABEL" />
            }
            combinedDataHistoricalLabel={
              <FormattedMessage id="CLIENT.DASHBOARD.DATAMODE.COMBINED_HISTORICAL_LABEL" />
            }
            error={formik.errors["historical_start_time"]}
          />
        </ConfigureArea>

        <ConfigureArea
          mb={30}
          title={intl.formatMessage({
            id: "CLIENT.DASHBOARD.FEATURE",
          })}
        >
          <Grid container>
            {FEATURES_OPTIONS.map((item) => (
              <Grid item xs={6} md={3} key={item.value}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values[item.value]}
                      onChange={(e) =>
                        formik.setFieldValue(item.value, e.target.checked)
                      }
                    />
                  }
                  label={item[`name_${language}`]}
                />
              </Grid>
            ))}
          </Grid>
        </ConfigureArea>

        <ConfigureArea
          mb={10}
          title={
            <Box display="flex" alignItems="center">
              {intl.formatMessage({
                id: "CLIENT.DASHBOARD.Y_AXIS_CONFIG",
              })}
              <Tooltip
                title={intl.formatMessage({
                  id: "CLIENT.DASHBOARD.Y_AXIS_CONFIG.NOTE",
                  defaultMessage:
                    "Giá trị nhỏ nhất phải nhỏ hơn giá trị lớn nhất",
                })}
              >
                <IconButton size="small">
                  <InfoIcon fontSize="normal" />
                </IconButton>
              </Tooltip>
            </Box>
          }
        >
          <Grid container spacing={3} alignItems="flex-start">
            {/* Left Y Axis */}
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <FormControlLabel
                style={{ marginBottom: "8px" }} // Adjust label alignment
                control={
                  <Checkbox
                    checked={formik.values.y_axis_left}
                    onChange={(e) =>
                      handleAxisToggle("y_axis_left", e.target.checked)
                    }
                  />
                }
                label={Y_AXIS_LABELS.y_axis_left[`name_${language}`]}
              />
              {formik.values.y_axis_left && (
                <Box mt={1} width="100%">
                  <FormControlLabel
                    style={{ marginBottom: "8px" }}
                    control={
                      <Checkbox
                        checked={formik.values.y_left_auto}
                        onChange={(e) => {
                          formik.setFieldValue("y_left_auto", e.target.checked);
                        }}
                      />
                    }
                    label={intl.formatMessage({
                      id: "CLIENT.DASHBOARD.AUTO_RESIZE",
                    })}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div style={{ minHeight: "70px", marginBottom: "8px" }}>
                        <SharedInputField
                          name="y_left_min"
                          label={intl.formatMessage({
                            id: "CLIENT.DASHBOARD.MIN_VALUE",
                          })}
                          type="number"
                          className="bg-white"
                          {...formik.getFieldProps("y_left_min")}
                          disabled={formik.values.y_left_auto}
                          errorMsg={
                            formik.touched["y_left_min"] &&
                            formik.errors["y_left_min"]
                          }
                          onChange={handleMinMaxChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ minHeight: "70px", marginBottom: "8px" }}>
                        <SharedInputField
                          name="y_left_max"
                          label={intl.formatMessage({
                            id: "CLIENT.DASHBOARD.MAX_VALUE",
                          })}
                          type="number"
                          className="bg-white"
                          {...formik.getFieldProps("y_left_max")}
                          disabled={formik.values.y_left_auto}
                          errorMsg={
                            formik.touched["y_left_max"] &&
                            formik.errors["y_left_max"]
                          }
                          onChange={handleMinMaxChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <SharedInputField
                        name="y_left_unit"
                        label={intl.formatMessage({
                          id: "CLIENT.DASHBOARD.AXIS_UNIT",
                        })}
                        type="text"
                        className="bg-white"
                        {...formik.getFieldProps("y_left_unit")}
                        errorMsg={
                          formik.touched["y_left_unit"] &&
                          formik.errors["y_left_unit"]
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Grid>

            {/* Right Y Axis */}
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <FormControlLabel
                style={{ marginBottom: "8px" }} // Adjust label alignment
                control={
                  <Checkbox
                    checked={formik.values.y_axis_right}
                    onChange={(e) =>
                      handleAxisToggle("y_axis_right", e.target.checked)
                    }
                  />
                }
                label={Y_AXIS_LABELS.y_axis_right[`name_${language}`]}
              />
              {formik.values.y_axis_right && (
                <Box mt={1} width="100%">
                  <FormControlLabel
                    style={{ marginBottom: "8px" }} // Align this with the checkbox
                    control={
                      <Checkbox
                        checked={formik.values.y_right_auto}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "y_right_auto",
                            e.target.checked
                          );
                        }}
                      />
                    }
                    label={intl.formatMessage({
                      id: "CLIENT.DASHBOARD.AUTO_RESIZE",
                    })}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div style={{ minHeight: "70px", marginBottom: "8px" }}>
                        <SharedInputField
                          name="y_right_min"
                          label={intl.formatMessage({
                            id: "CLIENT.DASHBOARD.MIN_VALUE",
                          })}
                          type="number"
                          className="bg-white"
                          {...formik.getFieldProps("y_right_min")}
                          disabled={formik.values.y_right_auto}
                          errorMsg={
                            formik.touched["y_right_min"] &&
                            formik.errors["y_right_min"]
                          }
                          onChange={handleMinMaxChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ minHeight: "70px", marginBottom: "8px" }}>
                        <SharedInputField
                          name="y_right_max"
                          label={intl.formatMessage({
                            id: "CLIENT.DASHBOARD.MAX_VALUE",
                          })}
                          type="number"
                          className="bg-white"
                          {...formik.getFieldProps("y_right_max")}
                          disabled={formik.values.y_right_auto}
                          errorMsg={
                            formik.touched["y_right_max"] &&
                            formik.errors["y_right_max"]
                          }
                          onChange={handleMinMaxChange}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <SharedInputField
                        name="y_right_unit"
                        label={intl.formatMessage({
                          id: "CLIENT.DASHBOARD.AXIS_UNIT",
                        })}
                        type="text"
                        className="bg-white"
                        {...formik.getFieldProps("y_right_unit")}
                        errorMsg={
                          formik.touched["y_right_unit"] &&
                          formik.errors["y_right_unit"]
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Grid>
          </Grid>
        </ConfigureArea>

        <Box mb={3}>
          <h2 className="dashboard-title-1">
            <FormattedMessage id="CLIENT.DASHBOARD.CHARTS" />
          </h2>
          <ChartList
            data={formik.values["dashboard_standard_chart_parameter"]}
            onChange={(data) => {
              formik.setFieldValue("dashboard_standard_chart_parameter", data);
            }}
            parameters={parameters || []}
          />
        </Box>
      </form>
    </div>
  );
};

StandardChartForm.propTypes = {};

export default StandardChartForm;
