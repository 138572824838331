import style0 from "../icon/line-width/style0.svg";
import style1 from "../icon/line-width/style1.svg";
import style2 from "../icon/line-width/style2.svg";
import style3 from "../icon/line-width/style3.svg";
import stylenull from "../icon/line-width/style_null.svg";
const data = [
  {
    icon: stylenull,
    value: 0,
    name_en: "Not show",
    name_vi: "Không hiển thị",
  },
  {
    icon: style0,
    value: 1,
    name_en: "1px",
    name_vi: "1px",
  },
  {
    icon: style1,
    value: 2,
    name_en: "2px",
    name_vi: "2px",
  },
  {
    icon: style2,
    value: 4,
    name_en: "3px",
    name_vi: "3px",
  },
  {
    icon: style3,
    value: 8,
    name_en: "8px",
    name_vi: "8px",
  },
];
export default data;
