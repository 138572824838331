import { formatString } from "app/utils/string-formatter";
import { HEADER_TOOLBAR_ICONS } from "../StandardChartCard_v2/constant";
import { getInstanceByDom } from "echarts/core";

const renderStandardChartDetails = (data, language, intl) => {
  const renderStandardChartTitle = () =>
    intl.formatMessage({
      id: "CLIENT.DASHBOARD.MODAL.NEW_STANDARD_CHART",
    });

  const name = (data && data[`name_${language}`]) || renderStandardChartTitle();

  const headerColor = (data && data["header_color"]) || "#1E1E2D";

  const showHeader = data && "show_header" in data ? data["show_header"] : true;

  return { name, headerColor, showHeader };
};

const shouldRenderToolbarIcon = (standardChartData, isPublic, iconName) => {
  const isStandardChartParamExist =
    standardChartData?.dashboard_standard_chart
      ?.dashboard_standard_chart_parameter?.length > 0;

  const {
    range_selection = false,
    zoom = false,
    full_screen = false,
  } = standardChartData?.dashboard_standard_chart || {};

  switch (iconName) {
    case HEADER_TOOLBAR_ICONS.RESTORE_ICON:
      return isStandardChartParamExist;

    case HEADER_TOOLBAR_ICONS.TODAY_ICON:
      return !isPublic && isStandardChartParamExist && range_selection;

    case HEADER_TOOLBAR_ICONS.ZOOM_OUT_ICON:
      return isStandardChartParamExist && zoom;

    case HEADER_TOOLBAR_ICONS.ZOOM_IN_ICON:
      return isStandardChartParamExist && zoom;

    case HEADER_TOOLBAR_ICONS.FULLSCREEN_ICON:
      return isStandardChartParamExist && full_screen;

    default:
      return false;
  }
};

function hexToHSL(hex, darkShape) {
  hex = hex.replace(/^#/, "");

  let r = parseInt(hex.substring(0, 2), 16) / 255;
  let g = parseInt(hex.substring(2, 4), 16) / 255;
  let b = parseInt(hex.substring(4, 6), 16) / 255;

  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);

  let l = (max + min) / 2;

  let h, s;

  if (max === min) {
    h = s = 0;
  } else {
    s = l > 0.5 ? (max - min) / (2 - max - min) : (max - min) / (max + min);

    switch (max) {
      case r:
        h = (g - b) / (max - min) + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / (max - min) + 2;
        break;
      default:
        h = (r - g) / (max - min) + 4;
        break;
    }

    h /= 6;
  }

  h = Math.round(h * 360);
  s = Math.round(s * 100);
  l = Math.round(l * 100);

  return `hsl(${h}, ${s}%, ${!darkShape ? l : 25}%)`;
}

const getFormattedValue = (value, format) => {
  if (isNaN(Number(value))) return 0;

  if (format) {
    return +formatString(format, value);
  }

  return Number(value);
};

const getLineStyleValue = (lineStyle) => {
  switch (lineStyle) {
    case "1":
      return [2];
    case "2":
      return [4];
    case "3":
      return [5, 1, 5];
    case "4":
      return [10];
    case "5":
      return [8, 1, 8];
    default:
      return [0];
  }
};

function getlineType(lineType) {
  if (lineType === "STEP") return { step: "middle", smooth: false };

  if (lineType === "SPLINE") return { step: null, smooth: true };

  return { step: null, smooth: false };
}

const getLineStyle = (
  markerType,
  markerSize,
  color,
  fillType,
  lineType,
  width,
  dashArray
) => {
  let areaStyle = { opacity: 0.7, color };
  let symbol = null;
  let symbolSize = markerType === 0 ? 0 : markerSize + 4;
  let itemStyle = {
    borderWidth: 3,
    borderColor: color,
    color: markerType === 1 || markerType === 2 ? color : "white",
  };

  if (markerType === 1 || markerType === 3) {
    symbol = "circle";
  } else if (markerType === 2 || markerType === 4) {
    symbol = "diamond";
  } else if (markerType === 5) {
    symbol = "rect";
  }

  if (fillType === "SOLID") areaStyle.opacity = 1;

  const { step, smooth } = getlineType(lineType);

  const lineStyle = {
    color,
    width,
    type: getLineStyleValue(dashArray),
  };

  return {
    symbol,
    symbolSize,
    itemStyle,
    step,
    smooth,
    lineStyle,
    ...(fillType !== "NONE" ? { areaStyle } : {}),
    // showSymbol: false,
  };
};

function getColumnStyle(width, color, fillType, fillOpacity) {
  let columnFillColor = "white";
  let borderColor = color;
  if (fillType === "SOLID") columnFillColor = color;
  else if (fillType === "GRADIENT") {
    borderColor = null;
    columnFillColor = {
      type: "linear",
      x: 0,
      y: 0,
      x2: 0,
      y2: 1,
      colorStops: [
        {
          offset: 0,
          color: hexToHSL(color, true),
        },
        {
          offset: 0.6,
          color: color,
        },
      ],
      global: false,
    };
  }

  return {
    itemStyle: {
      borderWidth: width,
      borderColor,
      color: columnFillColor,
      opacity: fillOpacity / 100,
    },
  };
}

function getChartOption({
  showGrid = false,
  showLegend = false,
  futureDateList = {},
  dataSeries,
  header_color,
  deviceType,
  listColor = [],
  padding = { top: 60, left: 60, right: 85, containLabel: true },
  yAxisConfig = [],
}) {
  // Configure Y axes
  const yAxes = yAxisConfig.map((config) => ({
    type: "value",
    position: config.position,
    name: config.unit || "",
    nameLocation: "end",
    nameGap: 20,
    nameTextStyle: {
      padding: [0, 0, 0, config.position === "right" ? 0 : 0], // 0px padding for both left and right axes
      color: "#666",
      verticalAlign: "top",
    },
    min: config.min,
    max: config.max,
    axisLabel: {
      formatter: (value) => `${value}`,
      margin: config.position === "right" ? 16 : 16, // 16px margin for both left and right axes
    },
    splitLine: {
      show: config.position === "left" ? showGrid : false,
      lineStyle: {
        type: "dashed",
        opacity: 0.3,
      },
    },
    axisLine: {
      onZero: false,
      show: true,
      lineStyle: {
        color: "#333",
      },
    },
  }));

  // Ensure at least one axis exists
  if (yAxes.length === 0) {
    yAxes.push({
      type: "value",
      splitLine: { show: showGrid },
      axisLine: { onZero: false, show: true },
    });
  }

  return {
    animation: false,
    tooltip: {
      confine: true,
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      trigger: "axis",
      axisPointer: {
        animation: false,
      },
      // renderMode: "richText",
      enterable: true,
      z: 100,
      formatter: function (params) {
        const inputDate = new Date(params[0].axisValue);

        const day = String(inputDate.getDate()).padStart(2, "0");
        const month = String(inputDate.getMonth() + 1).padStart(2, "0");
        const year = String(inputDate.getFullYear() + 1).padStart(2, "0");
        const hour = String(inputDate.getHours()).padStart(2, "0");
        const minute = String(inputDate.getMinutes()).padStart(2, "0");
        const second = String(inputDate.getSeconds()).padStart(2, "0");

        let rez = `<div style="text-align: start;"><p style="text-align: center;">${day}-${month}-${year} ${hour}:${minute}:${second}</p>`;
        params.forEach((item, index) => {
          var colorSpan = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:${listColor[index]}"></span>`;
          var xx =
            "<p>" +
            colorSpan +
            " " +
            item.seriesName +
            ": " +
            item.data[1] +
            "</p>";
          rez += xx;
        });

        return rez + "</div>";
      },
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: "none",
          icon: null,
          brushStyle: {
            color: "#cadafa55",
          },
        },
      },
      showTitle: false,
    },
    grid: {
      ...padding,
      right: yAxes.length > 1 ? 85 : padding.right,
      containLabel: true,
    },
    xAxis: [
      {
        type: "time",
        splitLine: { show: showGrid },
        axisLine: { onZero: false },
        axisLabel: {
          hideOverlap: true,
          formatter: {
            year: "{yyyy}",
            month: "{MMM}",
            day: "{d}",
            hour: "{HH}:{mm}",
            minute: "{HH}:{mm}",
            second: "{HH}:{mm}:{ss}",
            millisecond: "{hh}:{mm}:{ss} {SSS}",
            none: "{yyyy}-{MM}-{dd} {hh}:{mm}:{ss} {SSS}",
          },
        },
        ...(deviceType === "mobile"
          ? {
              axisPointer: {
                snap: true,
                lineStyle: {
                  color: header_color,
                  width: 2,
                },
                formatter: "{yyyy}-{MM}-{dd}",
                label: {
                  show: true,
                  backgroundColor: header_color,
                },
                handle: {
                  show: true,
                  color: header_color,
                  size: 30,
                },
              },
            }
          : {}),
      },
    ],
    yAxis: yAxes,
    dataZoom: [
      { type: "inside" },
      {
        show: true,
        type: "slider",
        labelFormatter: function (value) {
          const inputDate = new Date(value);

          const day = String(inputDate.getDate()).padStart(2, "0");
          const month = String(inputDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
          const hour = String(inputDate.getHours()).padStart(2, "0");
          const minute = String(inputDate.getMinutes()).padStart(2, "0");

          return `${day}-${month} ${hour}:${minute}`;
        },
      },
    ],
    legend: {
      show: showLegend,
      orient: "horizontal",
      center: "center",
      textStyle: {
        padding: [4, 20, 4, 20],
        borderRadius: 4,
      },
    },
    series: dataSeries,
  };
}

// Add new helper function to create series with Y-axis assignment
const createSeriesWithAxisAssignment = (
  seriesData,
  seriesConfig,
  yAxisIndex
) => {
  return {
    ...seriesData,
    yAxisIndex: yAxisIndex,
  };
};

const onZoomIn = (dashboardChartId) => {
  const chartDom = document.querySelector(`.echart-${dashboardChartId}`);
  const chart = getInstanceByDom(chartDom);
  const option = chart.getOption();
  let start = option.dataZoom[0].start;
  let end = option.dataZoom[0].end;
  const reductionFactor = start === 0 ? 0.2 : 0.1;
  if (start < end && end - start >= 1 && start >= 0 && end <= 100) {
    let gap = end - start;
    start = Math.min(end, start + gap * reductionFactor);
    end = Math.max(start, end - gap * reductionFactor);
  }

  chart.dispatchAction({
    type: "dataZoom",
    start,
    end,
  });
};

const onZoomOut = (dashboardChartId) => {
  const chartDom = document.querySelector(`.echart-${dashboardChartId}`);
  const chart = getInstanceByDom(chartDom);
  const option = chart.getOption();
  let start = option.dataZoom[0].start;
  let end = option.dataZoom[0].end;
  const reductionFactor = 0.1;
  if (start < end && start >= 0 && end <= 100) {
    let gap = end - start;
    start = Math.min(end, start - gap * reductionFactor);
    end = Math.max(start, end + gap * reductionFactor);
  }
  chart.dispatchAction({
    type: "dataZoom",
    start,
    end,
  });
};

const onResetZoom = (dashboardChartId) => {
  const chartDom = document.querySelector(`.echart-${dashboardChartId}`);
  const chart = getInstanceByDom(chartDom);
  chart.dispatchAction({
    type: "dataZoom",
    start: 0,
    end: 100,
  });
};

const toggleZoomSelect = (
  dashboardChartId,
  zoomSelectState,
  setZoomSelectState
) => {
  const chartDom = document.querySelector(`.echart-${dashboardChartId}`);
  const chart = getInstanceByDom(chartDom);
  chart.dispatchAction({
    type: "takeGlobalCursor",
    key: "dataZoomSelect",
    dataZoomSelectActive: !zoomSelectState,
  });
  setZoomSelectState((pre) => !pre);
};

export {
  getChartOption,
  getLineStyleValue,
  getLineStyle,
  getColumnStyle,
  getFormattedValue,
  renderStandardChartDetails,
  shouldRenderToolbarIcon,
  toggleZoomSelect,
  onResetZoom,
  onZoomIn,
  onZoomOut,
  createSeriesWithAxisAssignment,
};
