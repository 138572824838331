import {
  DEFAULT_ALARM_COLOR,
  DEFAULT_COLUMN_HEADER_COLOR,
  DEFAULT_DATA_MODE,
  DEFAULT_DISPLAY_COORDINATE,
  DEFAULT_GAUGE_CHART_TYPE,
  DEFAULT_HEADER_COLOR,
  DEFAULT_HISTORY_ROW_COLOR,
  DEFAULT_MAIN_COLOR,
  DEFAULT_MAP_TYPE,
  DEFAULT_NUMBER_OF_DIRETIONS,
  DEFAULT_PREDICTION_ROW_COLOR,
  DEFAULT_PREDICT_TIME_RANGE_TYPE,
  DEFAULT_RANGE_TYPE,
  DEFAULT_SCAN_IMAGE_TIME,
  DEFAULT_SORT_BY,
  DEFAULT_TEXT_COLOR,
  DEFAULT_TEXT_CONTENT_TYPE,
  DEFAULT_TIME_RANGE,
  DEFAULT_TIME_RANGE_TYPE,
  DEFAULT_ZOOM,
  IMAGE_TYPE,
  UPLOAD_TYPE,
} from "./contants";

export const CUSTOM_CHART_INITIALVALUES = {
  name_en: "",
  name_vi: "",
  header_color: DEFAULT_HEADER_COLOR,
  parameter_id: 1,
  direction_parameter_id: "",
  speed_parameter_id: "",
  station_id: "",
  show_header: true,
  data_mode: DEFAULT_DATA_MODE,
  prediction_time_range: DEFAULT_TIME_RANGE,
  prediction_time_range_type: DEFAULT_TIME_RANGE_TYPE,
  historical_time_range: DEFAULT_TIME_RANGE,
  historical_time_range_type: DEFAULT_TIME_RANGE_TYPE,
  number_of_direction: DEFAULT_NUMBER_OF_DIRETIONS,
  speed_unit: "",
  legend_title: "",
  zoom: false,
  legend: false,
  range_selection: false,
  full_screen: false,
  dashboard_custom_chart_range: [],
};

export const ALARM_SUMMARY_INITIALVALUES = {
  name_en: "",
  name_vi: "",
  parameter_id: null,
  station_id: null,
  header_color: DEFAULT_HEADER_COLOR,
  main_color: DEFAULT_MAIN_COLOR,
  alarm_color: DEFAULT_ALARM_COLOR,
  show_header: true,
  period: "Today",
  data_mode: DEFAULT_DATA_MODE,
  prediction_time_range: DEFAULT_TIME_RANGE,
  prediction_time_range_type: DEFAULT_TIME_RANGE_TYPE,
  historical_time_range: DEFAULT_TIME_RANGE,
  historical_time_range_type: DEFAULT_TIME_RANGE_TYPE,
};

export const ATTACHMENT_IMAGE_INITIALVALUES = {
  name_en: "",
  name_vi: "",
  header_color: DEFAULT_HEADER_COLOR,
  upload_type: UPLOAD_TYPE.STATIC_IMAGE,
  show_header: false,
  image_format: IMAGE_TYPE.JPEG,
  scan_time: DEFAULT_SCAN_IMAGE_TIME,
  link: "",
};

export const DIRECTION_INITIALVALUES = {
  name_en: "",
  name_vi: "",
  parameter_id: null,
  station_id: null,
  header_color: DEFAULT_HEADER_COLOR,
  main_color: DEFAULT_MAIN_COLOR,
  alarm_color: DEFAULT_ALARM_COLOR,
  show_header: true,
  number_of_direction: DEFAULT_NUMBER_OF_DIRETIONS,
  show_number: true,
  data_mode: DEFAULT_DATA_MODE,
  prediction_time_range: DEFAULT_TIME_RANGE,
  prediction_time_range_type: DEFAULT_TIME_RANGE_TYPE,
};

export const GAUGE_CHART_INITIALVALUES = {
  name_en: "",
  name_vi: "",
  header_color: DEFAULT_HEADER_COLOR,
  main_color: DEFAULT_MAIN_COLOR,
  alarm_color: DEFAULT_ALARM_COLOR,
  parameter_id: null,
  station_id: null,
  chart_type: DEFAULT_GAUGE_CHART_TYPE,
  range_type: DEFAULT_RANGE_TYPE,
  range_min: "",
  range_max: "",
  show_line: true,
  show_number: true,
  show_header: true,
  data_mode: DEFAULT_DATA_MODE,
  prediction_time_range: DEFAULT_TIME_RANGE,
  prediction_time_range_type: DEFAULT_TIME_RANGE_TYPE,
};

export const HISTORIC_TABLE_INITIALVALUES = {
  name_en: "",
  name_vi: "",
  show_header: true,
  sort_parameter_by: DEFAULT_SORT_BY,
  header_color: DEFAULT_HEADER_COLOR,
  column_header_color: DEFAULT_COLUMN_HEADER_COLOR,
  history_row_color: DEFAULT_HISTORY_ROW_COLOR,
  prediction_row_color: DEFAULT_PREDICTION_ROW_COLOR,
  data_mode: DEFAULT_DATA_MODE,
  prediction_time_range: DEFAULT_TIME_RANGE,
  prediction_time_range_type: DEFAULT_PREDICT_TIME_RANGE_TYPE,
  historical_time_range: DEFAULT_TIME_RANGE,
  historical_time_range_type: DEFAULT_TIME_RANGE_TYPE,
  display_mode: "portrait",
  aggregate_function: "RAW",
  interval: null,
};

export const LOCATION_MAP_INITIALVALUES = {
  name_en: "",
  name_vi: "",
  header_color: DEFAULT_HEADER_COLOR,
  text_color: DEFAULT_TEXT_COLOR,
  show_header: true,
  dashboard_location_map_station: [],
  dashboard_location_map_station_delete: [],
  display_coordinate: DEFAULT_DISPLAY_COORDINATE,
  zoom: DEFAULT_ZOOM,
  style: DEFAULT_MAP_TYPE,
  data_mode: DEFAULT_DATA_MODE,
  prediction_time_range: DEFAULT_TIME_RANGE,
  prediction_time_range_type: DEFAULT_TIME_RANGE_TYPE,
};

export const STANDARD_CHART_INITIALVALUES = {
  name_en: "",
  name_vi: "",
  show_header: true,
  header_color: DEFAULT_HEADER_COLOR,
  prediction_time_range: DEFAULT_TIME_RANGE,
  prediction_time_range_type: DEFAULT_TIME_RANGE_TYPE,
  historical_time_range: DEFAULT_TIME_RANGE,
  historical_time_range_type: DEFAULT_TIME_RANGE_TYPE,
  data_mode: DEFAULT_DATA_MODE,
  zoom: true,
  legend: true,
  range_selection: true,
  full_screen: true,
  dashboard_standard_chart_parameter: [],
  gridlines: true,
  y_axis_left: true,
  y_axis_right: false,
  y_left_min: 0,
  y_left_max: 0,
  y_right_min: 0,
  y_right_max: 0,
  y_left_auto: true,
  y_right_auto: true,
  y_left_label: "",
  y_right_label: "",
  y_left_unit: "",
  y_right_unit: "",
};

export const STATIC_TEXT_INITIALVALUES = {
  name_en: "",
  name_vi: "",
  show_header: false,
  content_type: DEFAULT_TEXT_CONTENT_TYPE,
  header_color: DEFAULT_HEADER_COLOR,
};

export const VALUE_INITIALVALUES = {
  name_en: "",
  name_vi: "",
  header_color: DEFAULT_HEADER_COLOR,
  main_color: DEFAULT_MAIN_COLOR,
  alarm_color: DEFAULT_ALARM_COLOR,
  parameter_id: null,
  station_id: null,
  show_header: true,
  data_mode: DEFAULT_DATA_MODE,
  prediction_time_range: DEFAULT_TIME_RANGE,
  prediction_time_range_type: DEFAULT_TIME_RANGE_TYPE,
};

export const VALUE_LIST_INITIALVALUES = {
  name_en: "",
  name_vi: "",
  show_header: true,
  sort_parameter_by: DEFAULT_SORT_BY,
  header_color: DEFAULT_HEADER_COLOR,
  data_mode: DEFAULT_DATA_MODE,
  prediction_time_range: DEFAULT_TIME_RANGE,
  prediction_time_range_type: DEFAULT_TIME_RANGE_TYPE,
};

export const AGGREGATE_TABLE_INITIALVALUES = {
  name_en: "",
  name_vi: "",
  header_color: DEFAULT_HEADER_COLOR,
  display_mode: "portrait",
  show_header: true,
  params: [],
  functions: [],
  data_mode: DEFAULT_DATA_MODE,
  station_id: null,
};
